//---------------------------------------------------------------------
// Vertical Rhythm
//---------------------------------------------------------------------

// Increment to be multiplied for each factor
// Value: number without unit
$vr-INCREMENT: $base-spacing-unit !default;

// Increment factors to be iterated through
// Value: number
$vr-FACTORS: 20 !default;

.vr {
    margin-bottom: remify($vr-INCREMENT);
}

// EXAMPLE OUTPUT
//
//    .vr--2x { margin-bottom: 10px; }
//    .vr--3x { margin-bottom: 15px; }
//
@for $VR from 2 through $vr-FACTORS {
    .vr--#{$VR}x {
        margin-bottom: remify($vr-INCREMENT * $VR);
    }
}
