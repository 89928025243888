//----------------------------------------------------------------------------
// Settings: Colors
//----------------------------------------------------------------------------

// USAGE
//
// a {
//     color: palette(neutral, mine-shaft);
// }
//
// named by: http://chir.ag/projects/name-that-color
$PALETTES: (
    // TODO: clean up colors, recategorize
        // brand is only here still to avoid breaking things since there's
        // lots of MHF code hanging around in this codebase
        brand:
        (
            san-juan: #2e426b,
            cloud-burst: #22355b,
            blue-mid: #2e426b,
            blue-light: #294e98,
            blue-dark: #0c1e44,
            gold: #ffb700,
            gold-dark: #f3ab11,
            blue-metallic: #204673,
        ),
    neutral: (
        black: #000000,
        cod-grey: #1c1c1c,
        mine-shaft: #333333,
        scorpion: #5f5f5f,
        fedora: #777677,
        nobel: #b4b3b3,
        nobal: #9e9e9e,
        alto: #d2d2d2,
        gallery: #eaeaea,
        mystic: #e5e9ef,
        athens-gray: #f1f3f5,
        white-smoke: #f6f6f6,
        white: #ffffff,
        carmine: #9d001b,
        carmine-alpha: #9d001b19,
        dusty-gray: #949494,
        quill-gray: #d5d6d2,
        casper: #b2c2d2,
        cornflow-blue: #eff2f5,
        apple: #70b145,
        crimson-red: #de111f,
        spanish-gray: #979797,
        grey-neutral: #e8e8e8,
        tropical-blue: #c3e8f3,
        oslo-grey: #8d8d8d,
        iridium: #3a3c3f,
        mirage: #1a202c,
        dusk: #486284,
        sky-blue: #6ccef1,
        golden-yellow: #fbb93c,
        mid-gray: #888888,
        bright-blue: #6fd0e7,
        light-gray: #dcdfe4,
        bright-yellow: #ffbe3b,
        dark-blue: #204673,
    ),
    status: (
        error: #ba002b,
    )
);

@function palette($palette, $tone) {
    @return map-get(map-get($PALETTES, $palette), $tone);
}

/****** GLOBAL TOKENS ******/
$primary-01-500: #333333;
$primary-02-500: #2e426b;
$secondary-01-600: #333333;
$secondary-01-500: #2e426b;
$secondary-01-400: #5f5f5f;
$secondary-02-500: #333333;
$neutral-black: #000000;
$neutral-900: #201b1b;
$neutral-800: #333333;
$neutral-700: #5a5a5a;
$neutral-600: #777677;
$neutral-500: #949494;
$neutral-400: #d5d6d2;
$neutral-300: #e5e9ef;
$neutral-200: #f6f6f6;
$neutral-100: #f1f3f5;
$neutral-white: #ffffff;

// Below token can be changed based on future requirements.
// $primary-01-600: #1c1c1c;
// $primary-01-400: #5f5f5f;
// $primary-02-600: #1c1c1c;
// $primary-02-400: #5f5f5f;
// $secondary-02-400: #5f5f5f;
// $secondary-02-300:#c8c7d4;
// $tertiary-01-600: #1c1c1c;
// $tertiary-01-500: #333333;
// $tertiary-01-400: #5f5f5f;
// $positive-500: #70b145;
// $alert-500: #f57a2b;
// $error-500: #de111f;
// $information-500: #008AD8;

//----------------------------------------------------------------------------
// Settings: Fonts
//----------------------------------------------------------------------------

// USAGE
//
// body {
//     font-family: font(family, base);
//     font-weight: font(weight, bold);
//     font-style: font(style, italic);
// }
//
$FONTS: (
    family: (
        base: #{'Roboto',
        'Helvetica Neue',
        'Arial',
        sans-serif},
        code: #{source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        'Courier New',
        monospace},
    ),
    weight: (
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    ),
    style: (
        normal: normal,
        italic: italic,
    ),
);

@function font($font, $style: 'regular') {
    @return map-get(map-get($FONTS, $font), $style);
}

//----------------------------------------------------------------------------
// Settings: Global
//----------------------------------------------------------------------------

// Breakpoints
// Includes pixel values
$viewport-width-sm: 500px;
$viewport-width-md: 768px;
$viewport-width-lg: 1025px;
$viewport-width-xl: 1440px;

// Breakpoint Strings
$viewport-width-sm-string: 'small';
$viewport-width-md-string: 'medium';
$viewport-width-lg-string: 'large';
$viewport-width-xl-string: 'xlarge';

// Decoration
$base-border: 1px solid palette(neutral, mystic);

// Landmarks
//  -header & navigation
$header-height-mobile: 65px;
$header-height-desktop: 104px;
$header-item-margin: 24px;
$nav-item-height: 67px;
$nav-item-width: 296px;
$nav-item-margin: 16px;
$nav-width-desktop: 296px;
$jump-nav-width: 300px;

// Spacing and Alignment
$desktop-gutter: 70px;
$mobile-gutter: 20px;
$base-spacing-unit: 5;

// Text
$base-font-size: 16;
$base-line-height: 1.231; // prevents ascenders and descenders from being cut off

// Z-Index
$z-index-modal: 9999;
$z-index-dropdown: 9998;
$z-index-text-over-image: 10;
$z-index-overlay-over-image: 9;
$z-index-jump-nav: 3;
$z-index-jump-sticky-nav: 101;

// Size
$size-fluid: auto;
$size-fixed-desktop-1440: 1440;
$size-fixed-desktop-1200: 1200;
$size-fixed-mobile-288: 288;

//----------------------------------------------------------------------------
// Tools: Functions
//----------------------------------------------------------------------------

// Accepts multiple values for shorthand properties
@function remify($values) {
    $rems: ();

    @each $value in $values {
        @if $value == 0 or $value == auto {
            $rems: append($rems, $value);
        } @else {
            $rems: append($rems, ($value / $base-font-size) + rem);
        }
    }
    @return $rems;
}

//----------------------------------------------------------------------------
// Tools: Mixins
//----------------------------------------------------------------------------

// Accessibly hide elements
@mixin isVisuallyHidden() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin isHidden() {
    display: none !important;
}

@mixin isInvisible() {
    visibility: hidden !important;
}

@mixin btnReset() {
    background-color: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

@mixin wrapper() {
    margin: 0 auto;
    max-width: remify(
        1440
    ); // TODO: this was a semi-arbitrary number, put thought into it
    padding-left: remify(30);
    padding-right: remify(30);

    @include bpMedium() {
        padding-left: remify(50);
        padding-right: remify(50);
    }

    @include bpLarge() {
        padding-left: remify(70);
        padding-right: remify(70);
    }
}

//----------------------------------------------------------------------
// conditional IE11 styles
//
// Provides a way to have styles applied only to IE11.
// Expects a single, simple selector such as '.componentName'
// Can be nested, but cannon nest within
//
// .componentName_isActive {
//     @mixin ie11 .componentName-child {
//         display: block;
//     }
// }
//----------------------------------------------------------------------
@mixin ie11($selector) {
    @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop,
        #{$selector} {
            @content;
        }
    }
}

// Scale Font Size
// https://css-tricks.com/books/fundamental-css-tactics/scale-typography-screen-size/
//
// @param font size (px) values as integers
@mixin scaleFontSize($min, $max) {
    font-size: calc(
        #{$min}px + (#{$max} - #{$min}) * ((100vw - 320px) / (1440 - 320))
    );
    line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 320px) / (1440 - 320)));
}

// Breakpoints
//
// For the purposes of consistency and ease of use, please nest breakpoint mixins
// inside of each CSS selector rather than isolating them at the bottom of each file.
@mixin bpSmall() {
    @media only screen and (min-width: $viewport-width-sm) {
        @content;
    }
}

@mixin bpMedium() {
    @media only screen and (min-width: $viewport-width-md) {
        @content;
    }
}

@mixin bpLarge() {
    @media only screen and (min-width: $viewport-width-lg) {
        @content;
    }
}

@mixin bpXLarge() {
    @media only screen and (min-width: $viewport-width-xl) {
        @content;
    }
}

@mixin bpMaxSmall() {
    @media only screen and (max-width: $viewport-width-sm - 1) {
        @content;
    }
}

@mixin bpMaxMedium() {
    @media only screen and (max-width: $viewport-width-md - 1) {
        @content;
    }
}

@mixin bpMaxLarge() {
    @media only screen and (max-width: $viewport-width-lg - 1) {
        @content;
    }
}

@mixin bpMaxXLarge() {
    @media only screen and (max-width: $viewport-width-xl - 1) {
        @content;
    }
}

// Focus defaults
@mixin defaultFocus {
    box-shadow: 0 0 0 1px palette(neutral, white),
        0 0 0 3px palette(neutral, mine-shaft);
    outline: none;
}

// Focus reset
@mixin resetFocus {
    outline: none;
    box-shadow: none;

    &::-moz-focus-inner,
    &::-moz-focus-outer {
        border: 0;
    }
}

/*
  This mixin can be used to set the object-fit:
  @include objectFit(contain);
  or object-fit and object-position:
  @include objectFit(cover, top);
*/
@mixin objectFit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin sneezeGuard($position: fixed) {
    position: $position;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.7);
    animation: fadeIn 0.3s;
}

/*
  This mixin can be used to set the object-fit:
  @include objectFit(contain);
  or object-fit and object-position:
  @include objectFit(cover, top);
*/
@mixin objectFit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin typographyH1() {
    font-family: font(family, Roboto);
    font-weight: font(weight, bold);
    font-size: remify(24);
    line-height: remify(28);

    @include bpMedium() {
        font-family: font(family, Roboto);
        font-weight: font(weight, bold);
        @include scaleFontSize(24, 64);
    }
}

@mixin typographyH2() {
    font-family: font(family, Roboto);
    font-weight: font(weight, bold);
    font-size: remify(14.8);

    @include bpMedium() {
        font-family: font(family, Roboto);
        font-weight: font(weight, bold);
        @include scaleFontSize(14.8, 24);
    }
}

@mixin typographyH3() {
    font-family: font(family, base);
    font-weight: font(weight, regular);
    font-size: remify(14.8);
    line-height: normal;

    @include bpMedium() {
        font-family: font(family, base);
        font-weight: font(weight, regular);
        font-size: remify(20);
        line-height: remify(26);
    }
}

@mixin typographyTitle() {
    font-family: font(family, base);
    font-weight: font(weight, bold);
    font-style: font(style, normal);
    font-size: remify(24);
    line-height: normal;
}

@mixin typographyBody() {
    font-family: font(family, base);
    font-weight: font(weight, bold);
    font-style: font(style, normal);
    font-size: remify(20);
}

@mixin typographyFooter() {
    font-family: font(family, base);
    font-weight: font(weight, regular);
    font-size: remify(16);
    line-height: remify(20.8);
    line-height: normal;
}

@mixin typographyH4() {
    font-family: font(family, base);
    font-size: remify(20);
    font-weight: font(weight, regular);

    @include bpMaxSmall() {
        font-size: remify(16);
    }
}

@mixin typographyH5() {
    font-family: font(family, base);
    font-weight: font(weight, light);
    color: palette(brand, blue-mid);
    font-style: font(style, italic);
    font-size: remify(12);
    line-height: remify(17);
}

@mixin typographyH6() {
    font-weight: font(weight, bold);
    font-family: font(family, base);
    font-size: remify(20);
}

@mixin typographyH7() {
    font-family: font(family, base);
    font-size: remify(20);
    font-style: normal;
    font-weight: font(weight, regular);
    line-height: remify(24);
}

@mixin typographyH8() {
    font-size: remify(30);
    font-weight: font(weight, regular);
    font-family: font(family, base);
    line-height: normal;
    font-style: normal;

    @include bpMaxSmall() {
        font-size: remify(16);
        line-height: remify(28);
    }
}

@mixin typographyH9() {
    font-size: remify(14);
    font-style: font(style, italic);
    font-weight: font(weight, medium);
    font-family: font(family, base);
}
@mixin typographyH10() {
    font-family: font(family, base);
    font-weight: font(weight, bold);
    font-size: remify(20);
}

.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: palette(neutral, mine-shaft);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
