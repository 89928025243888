.link-modal {
    .tier {
        padding: remify(60) 0;
    }

    .modal {
        position: relative;
        border-radius: 0px;

        .btn--close-x {
            color: palette(neutral, black);

            &:hover {
                svg {
                    background-color: palette(neutral, white-smoke);
                    border-radius: 50%;
                }
            }
        }
    }

    .modal__image svg {
        height: remify(85);
        width: remify(150);
    }

    .modal__title {
        margin-bottom: remify(25);

        @include bpMedium() {
            margin-bottom: remify(5);
        }
    }

    .modal__body {
        @include scaleFontSize(14, 18);
        color: palette(neutral, mine-shaft);
        font-weight: font(weight, light);
        line-height: 1.571;
        margin-bottom: remify(45);

        @include bpMedium() {
            margin-bottom: remify(5);
        }
    }

    .modal__cta {
        a {
            color: palette(neutral, white);
            background-color: palette(neutral, mine-shaft);
            border: 1px solid palette(neutral, mine-shaft);
            width: remify(208);
            height: unset;
        }
    }
}
