//---------------------------------------------------------------------
// Card
//---------------------------------------------------------------------

.card {
    position: relative;
    padding-top: remify(24);
    padding-bottom: remify(24);
    background-color: palette(neutral, white);
    border: $base-border;
    transition: all 0.15s ease-in-out;

    &--mapResult {
        padding: 0;
    }
}

.card--split {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card__section {
    padding-left: remify(24);
    padding-right: remify(24);

    &--indexOnCard {
        display: flex;
        padding: remify(16);
    }
}

.card__tag {
    width: fit-content;
    position: absolute;
    top: remify(0);
    right: 0;
    padding: remify(6 27);
    background-color: palette(brand, gold);
    border-bottom-left-radius: remify(8);

    @include bpLarge {
        right: remify(24);
        border-bottom-right-radius: remify(8);
    }
}
.card__tag--maroon {
    color: palette(neutral, white);
    background-color: palette(brand, maroon);
}

@include bpLarge() {
    .card--pad-lg\@large {
        padding-top: remify(50);
        padding-bottom: remify(50);

        & .card__section {
            padding-left: remify(40);
            padding-right: remify(40);
        }

        & .card__tag {
            right: remify(40);
        }
    }
}

.card--shadow {
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
    border: none;
}

.card--scale-active {
    border-left: 4px solid palette(neutral, mine-shaft);
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.025);
}

a.card {
    cursor: pointer;

    &:hover,
    &:focus {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.025);
    }
}

.card--scale {
    &:hover,
    &:focus {
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.025);
    }
}

a.card.focus-visible {
    border: 1px solid palette(neutral, mine-shaft);
}
