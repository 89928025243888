//---------------------------------------------------------------------
// Arrow Link
//---------------------------------------------------------------------

.arrow-link {
    display: inline-block;
    position: relative;
    margin-right: remify(48); // to pad over other inline block elements
    color: palette(brand, san-juan);
    font-size: remify(18);
    font-weight: font(weight, bold);
    text-decoration: none;

    // line
    &::before {
        pointer-events: none;
        content: '';
        display: block;
        height: 2px;
        width: 18px;
        position: absolute;
        top: calc(50% - 1px);
        right: 0;
        background-color: currentColor;
        transform: translateX(30px);
        transition: all 0.25s ease-in-out;
    }

    // arrow
    &::after {
        pointer-events: none;
        content: '';
        display: block;
        height: 6px;
        width: 6px;
        position: absolute;
        top: calc(50% - 4px);
        right: 0;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: currentColor;
        transform: translateX(30px) rotate(45deg);
        transition: all 0.25s ease-in-out;
    }

    &:hover,
    &:focus {
        color: palette(brand, san-juan);

        &::before {
            transform: translateX(36px);
        }

        &::after {
            transform: translateX(36px) rotate(45deg);
        }
    }
}

.arrow-link--left {
    color: palette(neutral, white);
    margin-left: remify(27);
    margin-right: 0px;
    // line
    &::before {
        left: -3.4rem;
    }

    //arrow
    &::after {
        left: -3.4rem;
        border-width: 0px 0px 2px 2px;
    }

    &:hover,
    &:focus {
        color: palette(neutral, white);

        &::before {
            color: palette(neutral, white);
        }

        &::after {
            color: palette(neutral, white);
        }
    }
}

.arrow-link--deemphasize {
    font-weight: font(weight, regular);
    line-height: remify(22);
}

.arrow-link--dark-theme {
    color: palette(neutral, white);

    &:hover,
    &:focus {
        color: palette(neutral, white);
    }
}
