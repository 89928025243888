//---------------------------------------------------------------------
// Grid
//---------------------------------------------------------------------
$grid-count-max: 12;
$grid-gutter: 20;

.grid {
    display: flex;
    flex-wrap: wrap;
}

.grid__col {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
}

.grid--gutter {
    margin: remify(-$grid-gutter) 0 0 remify(-$grid-gutter);

    > .grid__col {
        padding: remify($grid-gutter) 0 0 remify($grid-gutter);
    }
}

.grid--gutter-2x {
    margin: remify(-$grid-gutter * 2) 0 0 remify(-$grid-gutter * 2);

    > .grid__col {
        padding: remify($grid-gutter * 2) 0 0 remify($grid-gutter * 2);
    }
}

.grid--stretch {
    align-items: stretch;

    > .grid__col {
        display: flex;
        flex-direction: column;
        > * {
            flex-grow: 1;
            min-height: 1px;
        }
    }
}

// TODO: Mixin to generate col size classes?
.grid--fit > .grid__col {
    flex: 1 1 0px;
    min-width: 0px;
}

@include bpMedium() {
    .grid--fit\@medium > .grid__col {
        flex: 1 1 0px;
        min-width: 0px;
    }

    .grid--2up\@medium > .grid__col {
        width: 50%;
    }

    .grid--3up\@medium > .grid__col {
        width: 33%;
    }
}

@include bpLarge() {
    .grid--fit\@large > .grid__col {
        flex: 1 1 0px;
        min-width: 0px;
    }

    .grid--2up\@large > .grid__col {
        width: 50%;
    }

    .grid--3up\@large > .grid__col {
        width: 33.3333%;
    }

    // Generate grid columns
    //
    // EXAMPLE OUTPUT
    //
    // .o-grid-col_1of12\@lg { width: xx%; }
    // .o-grid-col_2of12\@lg { width: xx%; }
    // .o-grid-col_3of12\@lg { width: xx%; }
    // .o-grid-col_4of12\@lg { width: xx%; }
    //
    @for $i from 1 through $grid-count-max {
        $width: $i * (100% / $grid-count-max);

        .grid__col--#{$i}of#{$grid-count-max}\@lg {
            width: $width;
        }
    }
}

@include bpXLarge() {
    .grid--3up\@x-large > .grid__col {
        width: 33.3333%;
    }
}
