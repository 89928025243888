//----------------------------------------------------------------------------
// Object: Aspect Ratio - Forces an aspect ratio on the child element
//
// USAGE
// <div class="aspect-ratio aspect-ratio--16x9">
//     <img src="sunset.jpg" alt="Beautiful Montana sunset" />
// </div>
//----------------------------------------------------------------------------

.aspect-ratio {
    display: block; // Allows the class to be set on inline elements and have width and height
    position: relative; // Allows the child to be positioned relative to this container
    overflow: hidden; // Prevents content from breaking out of the container
}

// Assumes there is only one child image using <img />
.aspect-ratio img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.aspect-ratio::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
}

// Allows it moves into place on top of pseudo-element placeholder
.aspect-ratio--16x9 img,
.aspect-ratio--4x3 img,
.aspect-ratio--3x2 img,
.aspect-ratio--3x1 img {
    position: absolute;
}

// To add additional aspect ratio extensions:
// Calculate the percentage by dividing height over width (9/16 = .5625)
.aspect-ratio--16x9::before {
    padding-bottom: 56.25%;
}

.aspect-ratio--4x3::before {
    padding-bottom: 75%;
}

.aspect-ratio--3x2::before {
    padding-bottom: 66.66%;
}

.aspect-ratio--3x1::before {
    padding-bottom: 33.33%;
}
