//---------------------------------------------------------------------
// Button
//---------------------------------------------------------------------

.btn {
    @include btnReset();
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: remify(54);
    padding: remify(12 30);
    border-radius: remify(30);
    box-sizing: border-box;
    color: palette(brand, san-juan);
    font-size: remify(18);
    font-weight: font(weight, light);
    line-height: $base-line-height;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.btn--primary {
    padding: remify(18 36);
    background-color: palette(brand, san-juan);
    color: palette(neutral, white);

    &:hover,
    &:active {
        background-color: palette(brand, cloud-burst);
    }
}

.btn--secondary {
    padding: remify(18 36);
    border: 1px solid palette(brand, san-juan);
    background-color: palette(neutral, white);
    color: palette(brand, san-juan);

    &:hover,
    &:active {
        background-color: palette(brand, san-juan);
        color: palette(neutral, white);
    }
}

// similar to btn--primary but inverts colors instead of darken
.btn--tertiary {
    padding: remify(18 36);
    border: 1px solid currentColor;
    background-color: transparent;
    color: palette(neutral, white);

    &:hover,
    &:active {
        background-color: palette(neutral, white);
        color: palette(brand, san-juan);
    }
}

.btn--quaternary {
    padding: remify(18 40);
    background-color: palette(neutral, white);
    color: palette(brand, san-juan);
    font-size: remify(16);

    &:hover,
    &:active {
        background-color: palette(neutral, gallery);
    }
}

.btn--gold {
    padding: remify(18 36);
    background-color: palette(neutral, bright-yellow);
    color: palette(brand, blue-dark);

    &:hover,
    &:active {
        background-color: palette(brand, gold-dark);
    }
}

.btn--label-icon,
.btn--icon {
    & .btn__icon {
        display: block;
        width: 20px;
        height: 20px;
    }
}

// Icon with a label
.btn--label-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & .btn__icon {
        // Pad icon from the label text
        &:first-child {
            margin-right: 10px;
        }

        &:first-child {
            margin-right: 10px;
        }
    }
}

// Only icon
.btn--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: remify(54);
    padding: 0;
}

.btn--full {
    width: 100%;
}

.btn--wide {
    width: remify(192);
}

.btn--mobile-menu,
.btn--close-x {
    height: remify(44);
    width: remify(44);
    padding: 0;
    border-radius: 0;
    font-size: 0;
    color: palette(neutral, white);

    > svg {
        position: absolute;
        top: 0;
        left: 0;
    }
}
