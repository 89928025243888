//---------------------------------------------------------------------
// Site
//---------------------------------------------------------------------

.site {
    height: 100vh;
    width: 100%;
}

.site__header {
    background-color: palette(neutral, white);
}

.site__body {
    background-color: palette(neutral, white);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * {
        flex: 0 0 auto;
    }
}
